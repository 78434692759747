import React from 'react';
import { PlusIcon } from '../PlusIcon';
import { Upload } from '@pankod/refine-antd';

import styles from './UploadDragger.module.scss';

interface UploadDraggerProps {
    title: string;
    [key: string]: any;
}

export const UploadDragger: React.FC<UploadDraggerProps> = ({
    title,
    ...props
}) => {
    return (
        <Upload.Dragger beforeUpload={() => false} multiple={false} {...props}>
            <span className={styles.title}>
                <PlusIcon className={styles.circleIcon} />
                {title}
            </span>
        </Upload.Dragger>
    );
};
