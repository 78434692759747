import React from 'react';
import styles from './PlusIcon.module.scss';

interface PlusIconProps {
    [key: string]: any;
}

export const PlusIcon: React.FC<PlusIconProps> = () => {
    return (
        <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.plus}
        >
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#fefefe"
                stroke="none"
            >
                <path
                    d="M0 3855 c0 -696 2 -1265 5 -1265 3 0 12 17 20 37 21 50 52 82 100
104 l40 19 1103 0 1102 0 0 1103 0 1102 19 40 c20 45 70 92 107 101 13 3 24
10 24 15 l0 9 -1260 0 -1260 0 0 -1265z"
                />
                <path
                    d="M2600 5111 c0 -5 11 -12 24 -15 37 -9 87 -56 107 -101 l19 -40 0
-1102 0 -1103 1103 0 1102 0 40 -19 c45 -20 92 -70 101 -107 3 -13 10 -24 15
-24 l9 0 0 1260 0 1260 -1260 0 -1260 0 0 -9z"
                />
                <path
                    d="M5095 2493 c-21 -50 -52 -82 -100 -104 l-40 -19 -1102 0 -1103 0 0
-1102 0 -1103 -19 -40 c-20 -45 -70 -92 -107 -101 -13 -3 -24 -10 -24 -15 l0
-9 1260 0 1260 0 0 1265 c0 696 -2 1265 -5 1265 -3 0 -12 -17 -20 -37z"
                />
                <path
                    d="M0 1260 l0 -1260 1260 0 1260 0 0 9 c0 5 -11 12 -24 15 -37 9 -87 56
-107 101 l-19 40 0 1103 0 1102 -1102 0 -1103 0 -40 19 c-45 20 -92 70 -101
107 -3 13 -10 24 -15 24 l-9 0 0 -1260z"
                />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            >
                <path
                    d="M2475 5101 c-48 -22 -79 -54 -100 -103 l-15 -37 0 -1101 0 -1100
-1102 0 -1103 0 -40 -19 c-22 -10 -50 -29 -62 -42 -51 -56 -67 -147 -38 -217
21 -49 52 -81 100 -103 l40 -19 1103 0 1102 0 0 -1100 0 -1101 15 -37 c21 -49
52 -81 100 -103 l40 -19 45 0 45 0 40 19 c48 22 79 54 100 103 l15 37 0 1101
0 1100 1103 0 1102 0 40 19 c22 10 50 29 62 42 53 58 67 152 34 224 -22 48
-54 79 -103 100 l-37 15 -1101 0 -1100 0 0 1103 0 1102 -19 40 c-10 22 -29 50
-42 62 -58 53 -152 67 -224 34z"
                />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#7b7b7b"
                stroke="none"
            >
                <path
                    d="M2449 5087 c-34 -23 -79 -81 -79 -101 1 -6 8 3 17 19 23 43 43 65 83
86 19 10 30 18 24 19 -6 0 -26 -10 -45 -23z"
                />
                <path
                    d="M2650 5091 c40 -21 60 -43 83 -86 9 -16 16 -25 17 -19 0 6 -10 26
-23 45 -23 34 -81 79 -101 79 -6 -1 5 -9 24 -19z"
                />
                <path
                    d="M89 2727 c-34 -23 -79 -81 -79 -101 1 -6 8 3 17 19 23 43 43 65 83
86 19 10 30 18 24 19 -6 0 -26 -10 -45 -23z"
                />
                <path
                    d="M5010 2731 c40 -21 60 -43 83 -86 9 -16 16 -25 17 -19 0 6 -10 26
-23 45 -23 34 -81 79 -101 79 -6 -1 5 -9 24 -19z"
                />
                <path
                    d="M10 2494 c0 -20 45 -78 79 -101 19 -13 39 -23 45 -23 6 1 -2 8 -19
17 -43 23 -65 43 -86 83 -10 19 -18 30 -19 24z"
                />
                <path
                    d="M5091 2471 c-14 -33 -43 -62 -86 -84 -16 -9 -26 -16 -22 -17 13 0 50
21 75 44 25 22 56 75 49 82 -2 3 -10 -9 -16 -25z"
                />
                <path
                    d="M2370 134 c0 -20 45 -78 79 -101 19 -13 39 -23 45 -23 6 1 -2 8 -19
17 -43 23 -65 43 -86 83 -10 19 -18 30 -19 24z"
                />
                <path
                    d="M2731 111 c-14 -33 -43 -62 -86 -84 -16 -9 -26 -16 -22 -17 13 0 50
21 75 44 25 22 56 75 49 82 -2 3 -10 -9 -16 -25z"
                />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#0a0a0a"
                stroke="none"
            >
                <path d="M2548 5113 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
                <path d="M2360 4930 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 4930 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M183 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4923 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M5112 2565 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
                <path d="M2 2555 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
                <path d="M183 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4923 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 190 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 190 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2548 3 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#151515"
                stroke="none"
            >
                <path d="M2443 5083 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2663 5083 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2390 5030 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2720 5030 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M83 2723 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M5023 2723 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M30 2670 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M5080 2670 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M30 2450 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M5080 2450 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M83 2393 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M5023 2393 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2390 90 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2720 90 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2443 33 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2663 33 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#8d8d8d"
                stroke="none"
            >
                <path d="M2513 5113 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2593 5113 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 4960 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 4960 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M153 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4953 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M1 2594 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
                <path d="M5110 2600 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M5111 2524 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
                <path d="M0 2520 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M153 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4953 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 160 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 160 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2513 3 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2593 3 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#aaaaaa"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#4f4f4f"
                stroke="none"
            >
                <path d="M2414 5058 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />
                <path
                    d="M2695 5060 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"
                />
                <path d="M54 2698 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />
                <path
                    d="M5055 2700 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"
                />
                <path
                    d="M55 2420 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"
                />
                <path d="M5054 2418 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />
                <path
                    d="M2415 60 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"
                />
                <path d="M2694 58 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z" />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#262626"
                stroke="none"
            >
                <path d="M2533 5113 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2573 5113 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 4940 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 4940 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M173 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4933 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M5111 2584 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
                <path d="M0 2580 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M1 2534 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
                <path d="M5110 2540 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M173 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4933 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 180 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 180 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2533 3 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2573 3 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#595959"
                stroke="none"
            >
                <path d="M2523 5113 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2583 5113 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 4950 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 4950 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M163 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4943 2753 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M0 2590 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M5110 2590 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M0 2530 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M5110 2530 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M163 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M4943 2363 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2360 170 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2750 170 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z" />
                <path d="M2523 3 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
                <path d="M2583 3 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z" />
            </g>
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#292929"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#404040"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#0000ff"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#ffffff"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#00ffff"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#ff00ff"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            />
        </svg>
    );
};
