import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import { TitleProps } from '@pankod/refine-core';

import styles from './title.module.scss';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
    <Link to="/" className={styles.title}>
        ECTD
    </Link>
);
