import { NotificationProvider } from '@pankod/refine-core';
import { notification } from 'antd';

export const notificationProvider: NotificationProvider = {
    open: ({
        key,
        message,
        description,
        type,
        cancelMutation,
        undoableTimeout,
    }) => {
        notification.open({
            key,
            //@ts-ignore
            message: description ?? null,
            description: message ?? null,
            //@ts-ignore
            type,
        });
    },
    close: key => notification.close(key),
};
