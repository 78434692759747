import { Button, Modal, Spin } from '@pankod/refine-antd';

import styles from './Description.module.scss';

interface IProps {
    visible: boolean;
    onCancel: () => void;
    description: string | null;
    width?: string;
    isLoading?: boolean;
}

export const Description: React.FC<IProps> = ({
    visible,
    onCancel,
    width,
    description,
    isLoading,
}) => {
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={width}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Ok
                </Button>,
            ]}
            centered
            style={{ marginTop: 20, marginBottom: 20 }}
        >
            <Spin spinning={isLoading}>
                <div
                    style={{
                        minHeight: '50vh',
                    }}
                    className={styles.container}
                >
                    <pre className={styles.messageContent}>
                        {JSON.stringify(description, null, 4)}
                    </pre>
                </div>
            </Spin>
        </Modal>
    );
};
